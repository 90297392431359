import { type SupabaseClient, createClient } from '@supabase/supabase-js'

import type { Database } from './typing'

const { VITE_SUPABASE_URL, VITE_SUPABASE_KEY } = import.meta.env

let supabase: SupabaseClient<Database> | undefined

if (VITE_SUPABASE_URL && VITE_SUPABASE_KEY) {
	supabase = createClient<Database>(VITE_SUPABASE_URL, VITE_SUPABASE_KEY, {
		auth: {
			autoRefreshToken: true,
			persistSession: true,
			detectSessionInUrl: true,
		},
		db: {
			schema: 'public',
		},
		global: {
			headers: {
				'x-application-name': 'smotors-landing-page',
			},
		},
	})
}

export { supabase, type Database }
